export default function ProgressBar({title, value, target, displayType}){

    target = target ?? 100

    const complete = value >= target

    const percentage = (value/target) * 100
    const percentageRound = Math.round(percentage * 100) / 100

    const displayValue = displayType.toLowerCase() === "percentage" ? percentageRound + "%" : value + "/" + target

    return(
        <div>
            <div className="flex justify-between mb-1 space-x-2">
                <span className="text-base font-medium text-cyan-900 shrink whitespace-nowrap overflow-hidden text-ellipsis">{title}</span>
                <span className="text-sm font-medium text-cyan-900">{displayValue}</span>
            </div>
               <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div className="bg-cyan-600 h-2.5 rounded-full" style={{width: (complete ? 100 : percentageRound) + "%"}}></div>
            </div>
        </div>  
    )
}