import { Fragment, useState,useEffect, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import TypeSelector from '../components/formSteps/typeSelector'
import GoalName from './formSteps/goalName'
import TargetValue from './formSteps/targetValue'
import UserSelector from './formSteps/userSelector'

export default function StepByStepModal({goal, error, clearError, createGoal, updateGoal, deleteGoal, user, open, setOpen}) {

  const loggedInEmail = user?.user_metadata?.email

  const startingValue = useMemo(() => {
    if(goal){
      return ({step: 0, users: goal.users.map(x => x.email), name: goal.title, target: goal.target, type: goal.displayType})
    }
    else{
      return ({step: 0, users: [loggedInEmail], name: "", target: ""})
    } 
  }, [goal, loggedInEmail])
  const [formData, setFormData] = useState(startingValue)

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if(open === false){
      clearError()
      setSubmitted(false)
      setFormData(startingValue)
    }
  }, [startingValue, clearError, open, ])


  useEffect(() => {
    if(submitted && error === null){
      setOpen(false)
    }
  }, [setOpen, submitted, error])

  function goForward(){
    if(!formSteps[formData.step].valid){
      return;
    }
    setFormData(old => (old.step >= formSteps.length - 1 ? ({...old, step: formSteps.length - 1}) : ({...old, step: old.step + 1})))
  }
  function goBack(){
    setFormData(old => (old.step < 1 ? ({...old, step: 0}) : ({...old, step: old.step - 1})))
  }

  function addUser(user){
    setFormData(old => {
      var oldUsers = old.users;
      return ({...old, users: oldUsers.includes(user) ? [...oldUsers] : [...oldUsers, user]})
    })
  }

  function removeUser(user){
    setFormData(old => {
      var oldUsers = old.users;
      return ({...old, users: user === loggedInEmail ? oldUsers : oldUsers.filter(x => x !== user)})
    })
  }

  const formSteps = [
    {description: "", valid: formData.name, componenet: <GoalName name={formData?.name} setName={(n) => setFormData(old => ({...old, name: n}))}/>},
    {description: "Please select goal type:", valid: formData.type, componenet: <TypeSelector type={formData?.type} setType={(t) => setFormData(old => ({...old, type: old.type === t ? null : t}))}/>},
    {description: "", valid: formData.target, componenet: <TargetValue type={formData?.type} target={formData?.target} setTarget={(t) => setFormData(old => ({...old, target: t}))}/>},
    {description: "", valid: true, componenet: <UserSelector currentUserEmail={loggedInEmail} users={formData?.users} addUser={(u) => addUser(u)} removeUser={(u) => removeUser(u)}/>},
  ]

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all mx-4 sm:my-8 w-full max-w-lg">
                <div className="bg-white">
                    <div className='px-4 pt-5 flex space-x-4 items-center'>
                        <XMarkIcon onClick={() => setOpen(false)} className="h-6 w-6 text-cyan-600 cursor-pointer" aria-hidden="true" />
                        <div className="text-center sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-2xl font-bold text-gray-800">
                            New Goal
                        </Dialog.Title>
                        </div>
                    </div>
                    <p className='px-6 lg:px-8 pt-1 md:pt-4 text-base sm:text-lg text-gray-500'>{formSteps[formData.step].description}</p>
                </div>
                
                <div className="mx-auto max-w-full py-4 px-6 lg:px-8 md:pb-6">
                  
                  {/*Children*/}
                  {formSteps[formData.step].componenet}

                  {error &&
                    <p className='text-red-500 mt-5'>Error: {error}</p>
                  }
                  
                  {/*End Children*/}

                </div>
                    
                
                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-3 sm:w-auto sm:text-sm"
                    onClick={formData.step === formSteps.length-1 ? () => {goal ? updateGoal(goal, formData) : createGoal(formData); setSubmitted(true)} : () => goForward()}
                  >
                    {formData.step === formSteps.length-1 ? "Submit" : "Next"}
                  </button>

                  <button
                    type='button'
                    className={`inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${goal && formData.step === 0 ? "ml-3" : "sm:ml-3"} sm:w-auto sm:text-sm`}
                    onClick={formData.step === 0 ? () => setOpen(false) : () => goBack()}
                  >
                    {formData.step === 0 ? "Cancel" : "Back"}
                  </button>


                  {goal && formData.step === 0 &&
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {deleteGoal(goal); setOpen(false);}}
                    >
                      Delete
                    </button>
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}