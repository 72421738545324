import { PlusIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'

export default function UserSelector({users, addUser, removeUser, currentUserEmail}){
    
    const [currentEmail, setCurrentEmail] = useState("")

    function addUserPress(){
        addUser(currentEmail); 
        setCurrentEmail("")
    }

    return (
        <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Email Search: 
            </label>
            <div className='flex flex-row space-x-2 mb-4'>
                <input autoComplete="none" onKeyDown={(e) => e.key === 'Enter' ? addUserPress() : null} size={1} type="email" id="email" value={currentEmail} onChange={(e) => setCurrentEmail(e.target.value)} placeholder="Type email here..." className="grow shadow appearance-none border border-gray-200 sm:hover:border-gray-300 rounded py-2 px-3 text-gray-800 mb-3 leading-tight focus:outline-none focus:ring-0 focus:border-cyan-600 focus:hover:border-cyan-600 h-10"></input>
                <button onClick={addUserPress} className='bg-cyan-600 rounded-lg h-10 w-10 flex shadow-md sm:hover:bg-cyan-700 cursor-pointer'>
                    <PlusIcon className='h-6 w-6 mx-auto my-auto text-cyan-200'/>
                </button>
            </div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
                Users:
            </label>            
            
            <ul className='list-disc list-inside'>
                {users.map(user => {
                    return <li key={user} onClick={() => removeUser(user)} className={`${currentUserEmail === user ? undefined : "hover:cursor-pointer" }`}>{user}</li>
                })}
            </ul>
        </div>
    )
}