import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function StepByStepModal({clearSelected, loadEdit, goal, saveValues, open, setOpen}) {

  const [users, setUsers] = useState();

  useEffect(() => {
    setUsers(goal?.users)
  }, [goal])

  useEffect(() => {
    if(open === false){
      clearSelected()
    }
  }, [open, clearSelected])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all mx-4 sm:my-8 w-full max-w-lg">
                <div className="bg-white">
                    <div className='px-4 pt-5 flex space-x-4 items-center'>
                        <XMarkIcon onClick={() => setOpen(false)} className="h-6 w-6 text-cyan-600 cursor-pointer" aria-hidden="true" />
                        <div className="text-center sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-2xl font-bold text-gray-800 shrink whitespace-nowrap overflow-hidden text-ellipsis">
                            {goal?.title}
                        </Dialog.Title>
                        </div>
                    </div>
                </div>
                
                {/*children*/}

                <div className="mx-auto max-w-full py-4 px-6 lg:px-8 md:pb-6">
                  <div className={`grid grid-cols-1 ${users?.length > 1 ? "lg:grid-cols-2": undefined } gap-6 auto-rows-fr`}>
                      {users?.map(user => (
                        <div key={user.id} className='min-h-[80px] rounded-lg border-2 flex flex-col border-gray-200 sm:hover:border-gray-300 justify-center p-4 space-y-1.5'>
                          <label className="text-gray-700 text-sm font-bold mb-2 whitespace-nowrap overflow-hidden text-ellipsis" htmlFor="value">
                            {user.email}:
                          </label>
                          <input id="value" autoComplete="none" type="number" value={user.value} onChange={(e) => setUsers(old => old.map(u => u.id === user.id ? ({...u, value: e.target.value}) : u))} className="shadow appearance-none border border-gray-200 sm:hover:border-gray-300 rounded w-full py-2 px-3 text-gray-800 mb-3 leading-tight focus:outline-none focus:ring-0 focus:border-cyan-600 focus:hover:border-cyan-600"></input>
                        </div>
                      ))}

                  </div>
                </div>

                

                {/*end children*/}
                
                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6">

                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-3 sm:w-auto sm:text-sm"
                    onClick={(e) => {saveValues(goal, users); setOpen(false)}}
                  >
                    Save
                  </button>

                  <button
                    type='button'
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={loadEdit}
                  >
                    Edit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}