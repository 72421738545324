import {/*ClockIcon,*/ ReceiptPercentIcon, CalculatorIcon } from '@heroicons/react/24/outline'

export default function TypeSelector({type, setType}){

    function onClick(e){
        let newType = e.target.id || e.target.parentNode.id
        setType(newType)
    }

    return(
        <div className="grid grid-cols-2 gap-6 auto-rows-fr">
            <div id='numeric' onClick={onClick} className={`min-h-[120px] rounded-lg border-4 border-dashed flex flex-col cursor-pointer ${type === "numeric" ? "border-cyan-600 " : "border-gray-200 sm:hover:border-gray-300"}  group items-center justify-center px-2 space-y-1.5`}>
                <CalculatorIcon className={`h-6 w-6 sm:h-8 sm:w-8 text-center ${type === "numeric" ? "text-cyan-700 " : "text-gray-500 sm:group-hover:text-gray-800"}`}/>
                <h3 className={`text-xl sm:text-2xl font-bold tracking-tight ${type === "numeric" ? "text-cyan-700 " : "text-gray-500 sm:group-hover:text-gray-800"} text-center leading-6`}>Numeric</h3>
            </div>
            <div id='percentage' onClick={onClick} className={`min-h-[120px] rounded-lg border-4 border-dashed flex flex-col cursor-pointer ${type === "percentage" ? "border-cyan-600 " : "border-gray-200 sm:hover:border-gray-300"} group items-center justify-center px-2 space-y-1.5`}>
                <ReceiptPercentIcon className={`h-6 w-6 sm:h-8 sm:w-8 text-center ${type === "percentage" ? "text-cyan-700 " : "text-gray-500 sm:group-hover:text-gray-800"}`}/>
                <h3 className={`text-xl sm:text-2xl font-bold tracking-tight ${type === "percentage" ? "text-cyan-700 " : "text-gray-500 sm:group-hover:text-gray-800"} text-center leading-6`}>Percentage</h3>
            </div>
            {/*<div id='time' onClick={onClick} className={`min-h-[120px] rounded-lg border-4 border-dashed flex flex-col cursor-pointer ${type === "time" ? "border-cyan-600" : "border-gray-200 sm:hover:border-gray-300"} group items-center justify-center px-2 space-y-1.5`}>
                <ClockIcon className={`h-6 w-6 sm:h-8 sm:w-8 text-center ${type === "time" ? "text-cyan-700 " : "text-gray-500 sm:group-hover:text-gray-800"}`}/>
                <h3 className={`text-xl sm:text-2xl font-bold tracking-tight ${type === "time" ? "text-cyan-700" : "text-gray-500 sm:group-hover:text-gray-800"} text-center leading-6`}>Time Based</h3>
            </div>*/}
        </div>
    )
}