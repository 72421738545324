import ProgressBar from "../components/progressBar"

export default function GoalCard({onClick, goal}){

    const {title, displayType, target, users} = goal;
    return(
        <div className="" onClick={onClick}>
              <div className="h-full rounded-lg border-4 border-gray-200 cursor-pointer hover:border-gray-400 group">
                <div className='p-6 w-full flex flex-col gap-4'>
                    <h3 className="text-2xl font-bold tracking-tight text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">{title}</h3>

                    {users.map(user => {
                        return (<ProgressBar key={user.email} title={user.email} value={user.value} target={target} displayType={displayType}/>)
                    })}
                </div>
            </div>
        </div>
    )
}