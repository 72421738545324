import { supabase } from '../utils/supabase';
import { useState, useEffect, Fragment } from 'react';
import { useUser } from "../context/user";
import NavbarWrapper from '../components/navbarWrapper';
import GoalCardEmpty from '../components/goalCardEmpty';
import GoalCard from '../components/goalCard';
import StepByStepModal from '../components/stepByStepModal';
import GoalModal from '../components/goalModal';
//import NewGoalModal from '../components/newGoalModal';

export default function GoalsPage(){
    const {user} = useUser();

    const [newGoalModalOpen, setNewGoalModalOpen] = useState(false)
    const [goalModalOpen, setGoalModalOpen] = useState(false)
    const [selectedGoalId, setSelectedGoalId] = useState(null)
    const [goals, setGoals] = useState([])
    const [error, setError] = useState()

    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
    
          const {data:goals, error} = 
            await supabase
              .from('shared_goals')
              .select(`*, ignore:shared_goals_values!inner(*), users:shared_goals_values(*)`)
              .eq('ignore.email', user?.user_metadata?.email)
              .order('id', { ascending: true })
          
          console.log(goals, error)
          setGoals(goals)
    
        }
      
        // call the function
        fetchData()
          // make sure to catch any error
          .catch(console.error);
    
      }, [user])

      async function updateGoal(oldGoal, goalData){
        console.log("UPDATE GOAL", oldGoal, goalData)
        const goalResult = await supabase.from('shared_goals')
          .update([
            { 
              title: goalData.name,
              target: Number(goalData.target),
              displayType: goalData.type ?? "numeric"
            }
          ]).eq('id', oldGoal.id)


        if(!goalResult.error){
          await deleteGoalValues(oldGoal);

          await addGoalValues(goalData, goalResult, oldGoal, true);
        }
      }

      async function addGoalValues(goalData, goalResult, oldGoal=null, isEdit=false){
        const goalResultGoal = goalResult.data[0];
        const goalResultValues = await supabase.from('shared_goals_values')
          .insert(
            goalData.users.map(user => ({
                goal_id: goalResult.data[0].id,
                email: user,
                value: isEdit ? oldGoal?.users?.find(x => x?.email === user)?.value ?? 0 : 0
              })
            ))
        
        //if(goalResult.error){ }
        if(goalResultValues.error){
          if(!isEdit){
            await supabase.from('shared_goals').delete().match({ id: goalResult.data[0].id })
          }
          if(goalResultValues.error.code === "23503"){
            //"Key (email)=(chex) is not present in table "users"."
            const getEmailRe = /(?<=\(email\)=\().*?(?=\))/
            var invalidEmail = goalResultValues.error.details.match(getEmailRe)[0];
            setError(`User with email "${invalidEmail}" does not exist. Please try again`)
          }
        }
        else{
          if(isEdit){
            setGoals(old => old.map(goal => goal.id === goalResult.data[0].id ? ({...goalResultGoal, users: goalResultValues.data}) : goal))
          }
          else{
            setGoals(old => [...old, {...goalResult.data[0], users : goalResultValues.data}])
          }
          setError(null)
        }
      }

      async function createGoal(goalData){
        console.log(goalData)
        const goalResult = await supabase.from('shared_goals')
          .insert([
            { 
              title: goalData.name,
              target: Number(goalData.target),
              displayType: goalData.type ?? "numeric"
            }
        ])

        await addGoalValues(goalData, goalResult)
      }
      
      async function deleteGoal(goal){
        await deleteGoalValues(goal);
        const { data, error } = await supabase.from('shared_goals').delete().match({ id: goal.id })
        setGoals(old => old.filter(g => g.id !== goal.id))
        console.log(data,error)
      }

      async function deleteGoalValues(goal){
        await Promise.all(goal.users.map(async (value) => {
          const { data, error } = await supabase.from('shared_goals_values').delete().match({ id: value.id })
          console.log(data,error)
        }));

        setGoalModalOpen(false)
      }

      async function saveValues(goalToUpdateValues, newUsers){
        //Maybe update state first & for any errors: undo after?
        for (const user of newUsers) {
          const { error } = await supabase.from('shared_goals_values').update({value: user.value}).match({ id: user.id })
          
          if(!error){
            setGoals(old => {
              return old.map(goal => {
                return goal.id === goalToUpdateValues.id ? ({...goal, users: goal.users.map(u => u.id === user.id ? ({...u, value: user.value}): u)}) : goal
              })
            })
          }

        }
      }

      return (
        <Fragment>
            <GoalModal saveValues={saveValues} clearSelected={() => setSelectedGoalId(null)} loadEdit={() => setNewGoalModalOpen(true)} goal={goals.find(x => x.id === selectedGoalId)} open={goalModalOpen} setOpen={setGoalModalOpen}/>
            <StepByStepModal error={error} clearError={() => setError("")} user={user} createGoal={createGoal} updateGoal={updateGoal} open={newGoalModalOpen} setOpen={setNewGoalModalOpen} goal={goals.find(x => x.id === selectedGoalId)} deleteGoal={deleteGoal}/>
            <NavbarWrapper children={
                <div className="mx-auto max-w-7xl py-6 px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2 gap-6 auto-rows-fr">

                    {
                    goals.map(goal => {
                        return <GoalCard onClick={() => {setGoalModalOpen(true); setSelectedGoalId(goal.id)}} key={goal.id} goal={goal}/>
                    })
                    }
                    <GoalCardEmpty onClick={() => setNewGoalModalOpen(true)}/>
                </div>
            }/>
        </Fragment>
      )
}