import "./App.css";
import UserProvider, { useUser } from "./context/user";
import GoalsPage from "./pages/goals";
import LoginPage from "./pages/login";
import LandingPage from "./pages/landing";
import {
  Routes,
  Route,
  BrowserRouter,
  Outlet,
  Navigate,
} from "react-router-dom";

//https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
function PrivateOutlet() {
  const { user } = useUser();
  return user ? <Outlet /> : <Navigate to="/login" />;
}

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<LandingPage />} />,
          <Route path="/login" element={<LoginPage />} />,
          <Route path="/goals" element={<PrivateOutlet />}>
            <Route path="/goals" element={<GoalsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

//ToDO:
//Add ability to edit goal
//Add confirmation on delete
//not signed in: /login
//signed in: /goals
//Save to database
//Clicking allows edit values easily
//Clicking also shows "edit goal" which loads up "newGoalModal"
//Support  for display type (Time) --> "Going Down"
//Use Supabase AuthUI
//RLS - Role Level Security
//Profile Table - With Name - default to Identity (allow change)
//All rows must be the same size - not required for mobile

export default App;
