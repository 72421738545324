import Card from "../components/landing/card"

export default function LandingPage(){

    const productivityReference = "https://blog.trello.com/science-backed-reasons-you-shouldnt-share-your-goals#:~:text=A%20Dominican%20University%20study%20found,didn't%20write%20them%20down"

    return (
        <div className="p-6 bg-gray-800 text-gray-100 h-full min-h-screen flex">
            <div className="container grid gap-6 m-auto text-center md:grid-cols-2 xl:grid-cols-5">
                <Card>
                    <span className="block mb-2 text-cyan-400">SharedGoals.io</span>
                    <h1 className="text-4xl lg:text-5xl xl:text-6xl font-extrabold text-gray-50">Share Goals, Achieve Together</h1>
                    <p className="my-8">
                        <span className="font-medium text-gray-50">Social Accountability. </span>Share your goals and be over 85% more likely to achieve them.<sup><a className="text-cyan-400 text-[0.6rem]" target="_blank" rel="noreferrer" href={productivityReference}> (1)</a></sup>
                        {/*https://blog.trello.com/science-backed-reasons-you-shouldnt-share-your-goals#:~:text=A%20Dominican%20University%20study%20found,didn't%20write%20them%20down.*/}
                        {/*https://scholar.dominican.edu/cgi/viewcontent.cgi?article=1265&context=news-releases*/}
                    </p>
                    <form noValidate="" action="" className="self-stretch space-y-3 ng-untouched ng-pristine ng-valid">
                        <div>
                            <input id="name" type="text" placeholder="Your name" className="w-full rounded-md focus:ring focus:ring-cyan-400 border-gray-700 text-gray-900 placeholder:text-gray-500" />
                        </div>
                        <div>
                            <input id="lastname" type="text" placeholder="Email address" className="w-full rounded-md focus:ring focus:ring-cyan-400 border-gray-700 text-gray-900 placeholder:text-gray-500" />
                        </div>
                        <button type="button" className="w-full py-2 font-semibold rounded bg-cyan-400 text-gray-900">Join the waitlist</button>
                    </form>
                </Card>
                <img src="/img/Screenshot01.png" alt="" className="object-cover w-full rounded-md xl:col-span-3 bg-gray-900 my-auto p-0.5 shadow-md shadow-gray-900" />
            </div>
        </div>
    )
}