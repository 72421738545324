import { createContext, useState, useEffect, useContext } from "react";
import { supabase } from "../utils/supabase";
//import axios from 'axios'

const Context = createContext();

const Provider = ({children}) => {

    const [isLoading, setIsLoading] = useState(true);
    
    const [user, setUser] = useState(supabase.auth.user());
    const redirectURL = `${process.env.REACT_APP_APP_URL}/goals`
    /*useEffect(() => {
        axios.post('/api/set-supabase-cookie', {
            event: user ? 'SIGNED_IN' : "SIGNED_OUT",
            session: supabase.auth.session()
        })
    }, [user])*/

    useEffect(() => {
        const getUserProfile = async () => {
            const sessionUser = supabase.auth.user();

            if(sessionUser){
                /*const {data: profile} = await supabase
                    .from('supabase_saas_profile')
                    .select('*')
                    .eq('id', sessionUser.id)
                    .single()*/

                setUser({
                    ...sessionUser,
                    //...profile
                })
            }
            setIsLoading(false)
        }

        getUserProfile()

        supabase.auth.onAuthStateChange(() => {
            getUserProfile()
        })
    }, [])

    /*useEffect(() => {

        let subscription = null;

        if(user){
            const timer = setTimeout(() => subscription = subscribeToTable(), 1000);

            function subscribeToTable() {
                return (supabase
                .from(`supabase_saas_profile:id=eq.${user.id}`)
                .on('UPDATE', (payload) => {
                    console.log("Hit", payload)
                    setUser({
                        ...user,
                        ...payload.new
                    })
                }).subscribe())
            }

            return () => {
                if(!subscription){
                    return clearTimeout(timer);
                }
                supabase.removeSubscription(subscription)
            }
        }
    }, [user])*/

    const login = async () => {
        const { user, session, error } = await supabase.auth.signIn({
            provider: 'google',
          }, {
            redirectTo: redirectURL
          })
    }
    
    const logout = async() => {
        const { error } = await supabase.auth.signOut()
        setUser(null)
        //this.props.history.push('/')
    }

    const exposed = {
        user,
        //preferredIdentity: user ? user.identities[user.identities.length - 1] : null,
        login,
        logout,
        isLoading
    }

    return(
        <Context.Provider value={exposed}>
            {children}
        </Context.Provider>
    )
}


export const useUser = () => useContext(Context)

export default Provider;